import React, { useEffect, useState } from "react";
import {
    Row, Col, Container, Card, Button,
    Modal
} from "reactstrap";
import _fetch from '../../_fetch';
import './styles.scss';
import { useHistory } from "react-router-dom";
import { LuCalendarDays } from "react-icons/lu";
import Loader from "../../components/Loader/loader";
import CSVideoCard from "../../components/CSVideoCard/index.js";
import { useSelector } from "react-redux";
import CSVideoConfirmation from "../../components/Modals/CSVideoConfirmation/index.js";
import CSCalendar from "../../components/CSCalendar/index.js";



const initialPlanDetail = {
    left_videos: 0,
    percentage: 0,
    total: 0,
};


export default function CoreSculpt() {

    const [loading, setLoading] = useState(true);
    const user = useSelector((st) => st.user);
    const [category, setCategory] = useState([]);
    const [favouriteVideos, setFavouriteVideos] = useState([]);
    const [userPlanDetail, setUserPlanDetail] = useState(initialPlanDetail);
    const [watchedVideos, setWatchedVideos] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [suggested, setSuggested] = useState([]);
    const [selectedToWatchVideo, setSelectedToWatchVideo] = useState(null);
    const history = useHistory();
    const [activeButton, setActiveButton] = useState('YourActivity');

    const handleClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const fetchFaviouriteVideos = async () => {
        try {
            setLoading(true);
            let favVideosRes = await _fetch(`${process.env.REACT_APP_API_V2}/favourite-videos`, {
                method: 'GET',
            });
            if (favVideosRes.success) {
                setFavouriteVideos(favVideosRes.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const getdata = async () => {
        try {
            if (user.isSubscriptionPurchased) {

                const total = user.currentSubscription?.video_count;
                const premiumVideos = user.currentSubscription?.watchedVideos.filter(video => video.isFree === false);
                const left_videos = total - (premiumVideos.length ?? 0);
                let percentage = 0;
                if (user.currentSubscription?.video_count) {
                    percentage = user.currentSubscription?.video_count ? (left_videos / user.currentSubscription.video_count) * 100 : 0;
                }
                setUserPlanDetail({
                    left_videos,
                    total,
                    percentage,
                });
            }
            let watchedVideosData = await _fetch(`${process.env.REACT_APP_API_V2}/class/watched`, {
                method: 'GET',
            });
            if (watchedVideosData.success) {
                setWatchedVideos(watchedVideosData.response);
            }
            let categoryData = await _fetch(`${process.env.REACT_APP_API}/category?platform=MOBILE`, {
                method: 'GET',
            });

            if (categoryData.success) {
                setCategory(categoryData.response);
            }
            let suggestedVideos = await _fetch(`${process.env.REACT_APP_API_V2}/class/suggestion?page=0&limit=10`, {
                method: 'GET',
            });
            if (suggestedVideos.success) {
                setSuggested(suggestedVideos.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };


    function getBackgroundColor(index) {
        switch (index) {
            case 0:
                return "radial-gradient(circle, rgba(248,81,250,0.865983893557423) 14%, rgba(252,0,255,0) 60%)"; // Pink
            case 1:
                return "radial-gradient(circle, rgba(255,232,97,0.865983893557423) 14%, rgba(255,249,45,0) 60%)"; // Yellow
            case 2:
                return "radial-gradient(circle, rgba(214,255,97,1) 14%, rgba(179,255,101,0) 60%)"; // Green
            default:
                return "radial-gradient(circle, rgba(248,81,250,0.865983893557423) 14%, rgba(252,0,255,0) 60%)"; // Default to yellow
        }
    }


    useEffect(() => {
        getdata();
    }, [user?.currentSubscription]);

    useEffect(() => {
        fetchFaviouriteVideos();
    }, [user.favouriteVideos]);

    if (loading) {
        return (
            <>
                <Container className="mt-7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>
        )
    }


    return (
        <>
            {/* <Header /> */}
            <div id="core-sculpt-main" className="w-100">
                <div class='d-flex justify-content-between py-2 px-4 mx-md-4'>
                    <div>
                        <h2>Step 1:Select a category from below</h2>
                    </div>
                    <div>
                        <LuCalendarDays onClick={() => setShowCalendar(true)} className="icon-size" style={{ color: '#32325d', cursor: 'pointer' }} />
                    </div>
                </div>
                <Row
                    className="my-2 my-md-5 mx-0 w-100 px-2"
                >
                                        {category.map((elem, index) => (
                        <Col
                            key={elem._id}
                            xs={4}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            <div
                                className="d-flex flex-column align-items-center image-bg justify-content-between h-100"
                                onClick={() => {
                                    history.push(`/core-sculpt/${elem?.name?.replace(/[\s/]/g, '-').toLowerCase()}?id=${elem?._id}`, {
                                        description: elem.description,
                                        name: elem.name
                                    })
                                }
                                }
                            >
                                <div style={{ background: getBackgroundColor(index), borderRadius: '50%' }}>
                                    <img style={{ width: '100%', maxHeight: "300px" }} src={elem.thumbnail} alt='Images' />
                                </div>
                                <div className="text-center">
                                    <h3 className="d-md-none mb-0">{elem.name.split(' ')[0]}</h3>
                                    <h3 className="d-md-none mt-0">{elem.name.split(' ')[1]}</h3>
                                    <h3 className="d-none d-md-block">{elem.name}</h3>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <div className='d-flex justify-content-between pt-4 pb-3 px-4'>
                    <div className="d-flex flex-column flex-md-row">
                        <Button
                            className="button-styling"
                            style={{
                                color: activeButton === 'YourActivity' ? '#fff' : 'rgba(0, 0, 0, 0.25)', // Adjusted text color
                                cursor: 'pointer',
                                background: activeButton === 'YourActivity' ? 'linear-gradient(92.99deg, #C58BF2 -20.95%, #EEA4CE 52.48%)' : 'none',
                                padding: '6px 14px',
                                borderRadius: '15px',
                                boxShadow: 'none',
                                border: 'none'
                            }}
                            onClick={() => handleClick('YourActivity')}
                        >
                            Your Activity
                        </Button>

                        <Button
                            className="button-styling"
                            style={{
                                color: activeButton === 'Favourite' ? '#fff' : 'rgba(0, 0, 0, 0.25)', // Adjusted text color
                                cursor: 'pointer',
                                background: activeButton === 'Favourite' ? 'linear-gradient(92.99deg, #C58BF2 -20.95%, #EEA4CE 52.48%)' : 'none',
                                padding: '6px 14px',
                                borderRadius: '15px',
                                boxShadow: 'none',
                                border: 'none'
                            }}
                            onClick={() => handleClick('Favourite')}
                        >
                            Favourite
                        </Button>

                        <Button
                            className="button-styling"
                            style={{
                                color: activeButton === 'Suggested' ? '#fff' : 'rgba(0, 0, 0, 0.25)', // Adjusted text color
                                cursor: 'pointer',
                                background: activeButton === 'Suggested' ? 'linear-gradient(92.99deg, #C58BF2 -20.95%, #EEA4CE 52.48%)' : 'none',
                                padding: '6px 14px',
                                borderRadius: '15px',
                                boxShadow: 'none',
                                border: 'none'
                            }}
                            onClick={() => handleClick('Suggested')}
                        >
                            Suggested
                        </Button>


                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center ">
                        <div style={{
                            width: '100%',
                            height: 5,
                            borderRadius: 10,
                            backgroundColor: 'gray',
                        }}>
                            <div style={{
                                width: `${userPlanDetail.percentage}%`,
                                borderRadius: 10,
                                height: '100%',
                                backgroundColor: '#c58bf2',
                            }} />
                        </div>
                        <div style={{ fontSize: '12px', fontWeight: 600 }}>
                            {
                                `${userPlanDetail.left_videos} ${userPlanDetail.left_videos > 1 ? 'Videos' : 'Video'} left out of ${userPlanDetail.total}`
                            }
                        </div>
                    </div>
                </div>
                {
                    activeButton === 'YourActivity' && (
                        <div id="core-sculpt-focus-area" className="w-100 bg-color mb-4">
                            <div className="w-100">
                                {user.isSubscriptionPurchased && user.currentSubscription && (
                                    <>
                                        <Row className="mx-1 mx-md-4 d-flex">
                                            {
                                                watchedVideos && Array.isArray(watchedVideos) && watchedVideos.length > 0 ? (
                                                    watchedVideos?.map((item) => (
                                                        item?.video_id && (
                                                            <Col xs={12} lg={6} xl={4} >
                                                                <CSVideoCard
                                                                    key={item.video_id?._id}
                                                                    item={item.video_id}
                                                                    onPress={() => {
                                                                        history.push(`core-sculpt/video/${item.video_id?._id}`)
                                                                    }}
                                                                    progress={item.watched_percentage}
                                                                    isSuggestedClass={false}
                                                                    videoLastWatched={item.updatedAt}
                                                                />
                                                            </Col>
                                                        )
                                                    ))
                                                ) : (
                                                    <p
                                                        style={{
                                                            color: '#808080',
                                                            fontSize: '15px',
                                                            fontWeight: 300
                                                        }}
                                                    >
                                                        {`You haven't watched any video yet`}
                                                    </p>
                                                )
                                            }
                                        </Row>
                                    </>
                                )}

                            </div>
                        </div>
                    )
                }


                {
                    activeButton === 'Favourite' && (
                        <div id="core-sculpt-focus-area" className="w-100 bg-color mb-4">
                            <div className="w-100">
                                {favouriteVideos.length > 0 && (
                                    <>
                                        <Row className="mx-1 mx-md-4 d-flex">
                                            {favouriteVideos.map(item => (
                                                <Col
                                                    xs={12}
                                                    lg={6}
                                                    xl={4}
                                                    key={item._id}>
                                                    <CSVideoCard
                                                        item={item}
                                                        onPress={() => {
                                                            if (user.isSubscriptionPurchased && !!user?.currentSubscription?._id) {
                                                                const isWatched = watchedVideos?.find((watchedItem) => watchedItem.video_id?._id === item._id);
                                                                if (isWatched) {
                                                                    history.push(`core-sculpt/video/${item._id}`)
                                                                } else {
                                                                    setSelectedToWatchVideo(item?._id);
                                                                }
                                                            } else {
                                                                history.push('/coresculptapp#packages');
                                                            }
                                                        }}
                                                        isWatched={item?.isVideoWatchedAlready}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                )}

                            </div>
                        </div>
                    )
                }
                {
                    activeButton === 'Suggested' && (
                        <div id="core-sculpt-focus-area" className="w-100 bg-color mb-4">
                            <div className="w-100">
                                <Row className="mx-1 mx-md-4">
                                    {suggested.map((elem) => (
                                        <Col
                                            xs={12}
                                            lg={6}
                                            xl={4}
                                            className="w-100"                            >
                                            <CSVideoCard
                                                item={elem}
                                                key={elem?._id}
                                                onPress={() => {
                                                    if (user.isSubscriptionPurchased && !!user?.currentSubscription?._id) {
                                                        const isVideoWatched = user?.currentSubscription?.watchedVideos?.some(((watchedItem) => watchedItem.video_id?._id === elem._id))
                                                        if (isVideoWatched) {
                                                            history.push(`core-sculpt/video/${elem?._id}`)
                                                        } else {
                                                            setSelectedToWatchVideo(elem?._id);
                                                        }
                                                    } else {
                                                        history.push('/coresculptapp#packages')
                                                    }
                                                }}
                                                isWatched={elem.isVideoWatchedAlready}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    )
                }


            </div >
            <CSVideoConfirmation
                isOpen={selectedToWatchVideo !== null}
                toggle={() => {
                    setSelectedToWatchVideo(null);
                }}
                onContinuePress={() => {
                    history.push(`/core-sculpt/video/${selectedToWatchVideo}`);
                    setSelectedToWatchVideo(null);
                }}
            />
            <Modal isOpen={showCalendar} toggle={() => setShowCalendar(false)}>
                <CSCalendar />
            </Modal>

        </>

    );

};
