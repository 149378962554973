import { useEffect, useMemo, useRef, useState } from "react"
import {
    Row, Col, Container, Card, CardBody
} from "reactstrap";
import Loader from "../../components/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { HiFire } from "react-icons/hi";
import { CgGym } from "react-icons/cg";
import {
    LoginAction
} from "../../actions/userActions";
import CSVideoCard from "../../components/CSVideoCard";
import _fetch from "../../_fetch";
import {
    toast
} from "react-toastify";
import CSVideoConfirmation from "../../components/Modals/CSVideoConfirmation/index.js";
import CSVideoExhausted from "../../components/Modals/CSVideoExhausted/index.js";

const initialPlanDetail = {
    left_videos: 0,
    percentage: 0,
    total: 0,
};

const initialVideoData = {
    url: '',
    watched_time: 0,
    watched_percentage: 0,
    title: '',
    description: '',
};

export default function CSVideoPlayer(){
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        id: videoId = ''
    } = useParams();
    
    const user = useSelector((st) => st.user);
    const userPlanDetail = useMemo(() => {
		if (user?.currentSubscription) {
			const total = user.currentSubscription?.video_count;
            const premiumVideos = user.currentSubscription?.watchedVideos.filter(video => video.isFree === false);
			const left_videos = total - (premiumVideos.length ?? 0);
			let percentage = 0;
			if (user.currentSubscription?.video_count) {
				percentage = user.currentSubscription?.video_count ? (left_videos / user.currentSubscription.video_count) * 100 : 0;
			}
			return ({
				left_videos,
				percentage,
				total,
			});
		} else {
			return initialPlanDetail;
		}
	}, [user?.currentSubscription]);

    const [selectedToWatchVideo, setSelectedToWatchVideo] = useState(null);
    const [videoData, setVideoData] = useState(initialVideoData);
    const [loading, setLoading] = useState(true);
    const [isVideosExhausted, setIsVideosExhausted] = useState(false);
    const [suggestedVideos, setSuggestedVideos] = useState([]);
    const [videoOperationOnGoing, setVideoOperationOnGoing] = useState(false);
    const lastUpdated = useRef(0);

    async function init(){
        try {
            setLoading(true);
            const res = await _fetch(`${process.env.REACT_APP_API_V2}/class/signurl?id=${videoId}`);
            if (res.success) {
                setVideoData({
                    ...res.response,
                    isFavourite: user?.favouriteVideos?.includes(videoId) ?? false,
                });
                if (res.response.isNew){
                    dispatch(LoginAction({
                        ...user,
                        currentSubscription: {
                            ...user.currentSubscription,
                            watchedVideos: [
                                ...user.currentSubscription.watchedVideos,
                                {
                                    video_id: videoId,
                                    watched_time: 0,
                                    watched_percentage: 0,
                                }
                            ]
                        }
                    }))
                }

            } else if (res.status === 402){
                setIsVideosExhausted(true);
            } else {
                history.push('/core-sculpt');
            }
            const suggestedVideos = await _fetch(`${process.env.REACT_APP_API_V2}/class/suggestion?page=0&limit=6`);
            if (suggestedVideos.success) setSuggestedVideos(suggestedVideos.response);
        } catch (err) {
            console.error(err);
        } finally {
            console.log('finally');
            setLoading(false);
        }
    }

    async function updateWatchTime(watched_time, total_time){
        try {
            await _fetch(`${process.env.REACT_APP_API_V2}/class/watch-time-tracker/${videoId}`, {
                method: 'POST',
                body: {
                    watched_time,
                    total_time,
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    async function handleProgress(e) {
        const { currentTime, duration } = e.target;
        if (parseInt(duration) !== parseInt(videoData.duration)) {
            setVideoData({
                ...videoData,
                duration: parseInt(duration),
            });
        }
        if (currentTime - lastUpdated?.current >= 20){
            lastUpdated.current = currentTime;
            updateWatchTime(currentTime, duration);
        } else if (currentTime >= duration) {
            updateWatchTime(duration, duration);
        }
    }

    async function setAsFavouriteVideo(){
        try {
            setVideoOperationOnGoing(true);
            const res = await _fetch(`${process.env.REACT_APP_API_V2}/favourite-videos/${videoId}`, {
                method: 'POST',
            });
            if (res.success) {
                setVideoData({
                    ...videoData,
                    isFavourite: true,
                });
                dispatch(LoginAction({
                    ...user,
                    favouriteVideos: [
                        ...user.favouriteVideos,
                        videoId
                    ]
                }))
                toast.success('Video added to favourites');
            } else {
                toast.error('Something went wrong');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setVideoOperationOnGoing(false);
        }
    }

    async function removeAsFavouriteVideo(){
        try {
            setVideoOperationOnGoing(true);
            const res = await _fetch(`${process.env.REACT_APP_API_V2}/favourite-videos/${videoId}`, {
                method: 'DELETE',
            });
            if (res.success) {
                setVideoData({
                    ...videoData,
                    isFavourite: false,
                });
                dispatch(LoginAction({
                    ...user,
                    favouriteVideos: user.favouriteVideos.filter((v) => v !== videoId)
                }))
                toast.success('Video removed from favourites');
            } else {
                toast.error('Something went wrong');
            }
        } catch (err) {
            console.error(err);
        } finally {
            setVideoOperationOnGoing(false);
        }
    }

    const timeToHourAndMin = (time) => {
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time - (hours * 3600)) / 60);
		return hours > 0 ? `${hours}h ${minutes}m` : `${minutes} min`;
	};

    useEffect(() => {
        if (videoId) init();
        else {
            history.push('/core-sculpt');
        }
    }, [videoId]);

    if (loading) {
        return (
            <>
                <Container className="mt-7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>
        )
    }

    return (
        <>
            <Container className="mt-7" fluid>
				<Card className="shadow border-0">
					<CardBody
                        className='row p-1 md-p-4 w-100 m-0'
                        style={{ minHeight: 500 }}
                    >
						<Row
                            className='w-100 m-0'
                        >
							<Col
                                xs={12}
                            >
								<div
                                    className="p-2 md-p-4"
                                >
                                    <div className="border-0 d-flex flex-row justify-content-between mb-3">
                                        <div>
                                            <h3>
                                                {videoData.title}
                                            </h3>
                                            {
                                                videoData.intensity?.length > 0 && (
                                                    <p
                                                        style={{
                                                            color: "#c58bf2"
                                                        }}
                                                    >
                                                        {
                                                            videoData.intensity[0]
                                                        }
                                                    </p>
                                                )
                                            }
                                        </div>
                                        <FaHeart
                                            size={30}
                                            color={videoData.isFavourite ? 'red' : 'gray'}
                                            onClick={() => {
                                                if (!videoOperationOnGoing){
                                                    if (videoData.isFavourite) removeAsFavouriteVideo();
                                                    else setAsFavouriteVideo();
                                                } else {
                                                    toast.info('Operation in progress');
                                                }
                                            }}
                                            style={{
                                                minWidth: 30,
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="flex justify-center items-center w-100 h-auto mb-2"
                                    >
                                        {
                                            videoData.url
                                                ?
                                                <video
                                                    src={`${videoData.url}#t=${videoData.watched_time}`}
                                                    style={{ minHeight: 450, borderRadius: 10 }}
                                                    className='w-100 h-auto'
                                                    controls={true}
                                                    autoPlay
                                                    onTimeUpdate={handleProgress}
                                                    controlsList='nodownload'
                                                >
                                                </video>
                                                :
                                                'Something went wrong'
                                        }
                                    </div>
                                    <div
                                        className="d-flex justify-content-between mb-2"
                                    >
                                        <div
                                            className="d-flex align-items-center flex-wrap"
                                            style={{
                                                gap: 10
                                            }}
                                        >
                                            <div
                                                className="px-3 py-1"
                                                style={{
                                                    borderRadius: 999,
                                                    backgroundColor: "#f7f6fa"
                                                }}
                                            >
                                                <p
                                                    className="m-0"
                                                    style={{
                                                        fontWeight: '500'
                                                    }}
                                                >
                                                    {
                                                        videoData?.duration ? timeToHourAndMin(videoData.duration) : 'Loading ...'
                                                    }
                                                </p>
                                            </div>
                                            {
                                                videoData.focus_area && videoData.focus_area?.name && (
                                                    <div
                                                        className="px-3 py-1"
                                                        style={{
                                                            borderRadius: 999,
                                                            backgroundColor: "#f7f6fa"
                                                        }}
                                                    >
                                                        <p
                                                            className="m-0"
                                                            style={{
                                                                fontWeight: '500'
                                                            }}
                                                        >
                                                            <HiFire
                                                                size={20}
                                                                color="#c58bf2"
                                                                className="mr-2"
                                                            />
                                                            {videoData.focus_area.name}
                                                        </p>
                                                    </div>   
                                                )
                                            }
                                            {
                                                videoData.category && videoData.category?.name && (
                                                    <div
                                                        className="px-3 py-1"
                                                        style={{
                                                            borderRadius: 999,
                                                            backgroundColor: "#f7f6fa"
                                                        }}
                                                    >
                                                        <p
                                                            className="m-0"
                                                            style={{
                                                                fontWeight: '500'
                                                            }}
                                                        >
                                                            <CgGym
                                                                size={20}
                                                                color="#c58bf2"
                                                                className="mr-2"
                                                            />
                                                            {videoData.category.name}
                                                        </p>
                                                    </div>   
                                                )
                                            }
                                        </div>
                                        <div
                                            style={{
                                                width: '15%',
                                                minWidth: 100,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: 5,
                                                    borderRadius: 10,
                                                    backgroundColor: 'gray',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${userPlanDetail.percentage}%`,
                                                        borderRadius: 10,
                                                        height: '100%',
                                                        backgroundColor: "#c58bf2"
                                                    }}
                                               />
                                               <p
                                                    style={{
                                                        fontWeight: '600',
                                                        lineHeight: '1.5'
                                                    }}
                                                    className="m-0 "
                                               >
                                                {
                                                    `${userPlanDetail.left_videos} / ${userPlanDetail.total} Videos`
                                                }
                                               </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="my-2"
                                    >
                                        <h3
                                            className="h3"
                                        >
                                            Description
                                        </h3>
                                        <p>
                                            {
                                                videoData.description ? videoData.description : 'No description available'
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <h3
                                            className="h3"
                                        >
                                            Related Videos
                                        </h3>
                                        <Row>
                                            {
                                                suggestedVideos.map((video) => (
                                                    <Col
                                                        xs={12}
                                                        lg={6}
                                                        key={video._id}
                                                    >
                                                        <CSVideoCard
                                                            item={video}
                                                            isWatched={video?.isVideoWatchedAlready}
                                                            onPress={() => {
                                                                if (user.isSubscriptionPurchased && !!user?.currentSubscription?._id) {
                                                                    const isVideoWatched = user?.currentSubscription?.watchedVideos?.some(((watchedItem) => watchedItem.video_id?._id === video._id))
                                                                    if (isVideoWatched) {
                                                                        history.push(`/core-sculpt/video/${video._id}`)
                                                                    } else setSelectedToWatchVideo(video._id)
                                                                } else {
                                                                    history.push('/coresculptapp#packages')
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>
                                </div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
            <CSVideoConfirmation
                isOpen={selectedToWatchVideo !== null}
                toggle={() => {
                    setSelectedToWatchVideo(null);
                }}
                onContinuePress={() => {
                    history.push(`/core-sculpt/video/${selectedToWatchVideo}`)
                    setSelectedToWatchVideo(null);
                }}
            />
            <CSVideoExhausted
                isOpen={isVideosExhausted}
                toggle={() => {
                    setIsVideosExhausted(false);
                    history.push('/core-sculpt');
                }}
                onContinuePress={() => {
                    setIsVideosExhausted(false);
                    history.push('/coresculptapp#packages');
                }}
            />
        </>
    )
}