import React from "react";
import { Link } from "react-router-dom";
import {
	Button,
	Row,
	Col,
} from "reactstrap";
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg';
import './login.scss';
export default function DeleteMyAccount() {
	return (
		<>
			<div
				className='container'
			>
				<Row>
					<Col lg={5} md={8} className="">
						<div className="d-none d-lg-flex">
							<img
								src={leftTwigSVG}
								alt='..'
								className='left-twig-svg'
								style={{
									position: 'absolute',
									zIndex: '3',
									height: '15%',
									width: '15%',
									right: '-12%',
									top: '-2%'
								}}
							/>
						</div>
						<div className="login-style ">
							<div className="navbar-header  mar-top-bot">
								<Link className="navbar-brand logo-space" to='/'>
									<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`} alt="" style={{
										maxWidth: '200px',
										objectFit: 'contain',
									}} />
								</Link>
							</div>
							<div>
								<h2 className="head-font mar-top-bot my-4">Delete Account</h2>
							</div>
							<div className="text-center mar-top-bot text-dark text-xl my-4 text-steps-font">
								Steps to Delete an Account
							</div>
							<ul className="ul-list-style text-center ml-4">
								<li>
									<div className="text-left mar-top-bot text-dark text-lg my-4">
									Login your Account in an CoreSculpt App
									</div>
								</li>
								<li>
									<div className="text-left mar-top-bot text-dark text-lg my-4">
									Go to Profile Section
									</div>
							</li>
							<li>
									<div className="text-left mar-top-bot text-dark text-lg my-4">
									Click on Delete Account Button
									</div>
							</li>
								<li>
									<div className="text-left mar-top-bot text-dark text-lg my-4">
									Your Account will get Deleted Successfully
									</div>
							</li>
							</ul>

							<div className='d-flex flex-column flex-md-row mt-4 buttons-container'>
								<Button className="my-md-4 my-2 mx-2 w-100 " color="dark" type="submit" onClick={() => {
																		window.open(
																			'https://play.google.com/store/apps/details?id=com.keepwellmantras',
																			'_blank'
																		);
																	}}>
										Download Android App
									</Button>
									<Button className="my-md-4 my-2 mx-2 w-100" color="dark" type="submit" onClick={() => {
																		window.open(
																			'https://apps.apple.com/us/app/coresculpt/id6450386191',
																			'_blank'
																		);
																	}}>
										Download iOS App
									</Button>
								</div>
						
							<div className="d-none d-lg-flex">
								<img
									src={rightTwigSVG}
									alt='..'
									className='right-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '15%',
										width: '15%',
										left: '-15%',
										top: '95%'
									}}
								/>
							</div>

						</div>
					</Col >

					<Col lg={5} className='d-none d-lg-flex' style={{
						marginTop: "4%",
						marginLeft: '12%'
					}}>
						<div >
							<div
								style={{
									width: '90%',
									height: '90%',
									position: 'absolute',
									top: '-35px',
									marginTop: '10',
									left: '0',
									backgroundColor: '#E4D0F4',
									borderRadius: '80px 0px 80px 0px',
									zIndex: '1',
								}}
								className='underlay'
							/>
							<div>
								<img
									src={leftTwigSVG}
									alt='..'
									className='left-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '25%',
										width: '25%',
										right: '-70px',
										top: '50px'
									}}
								/>
							</div>

							<div className="about-opening">

								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
									alt=""
									style={{
										height: '90%',
										width: '90%',
										top: '0',
										objectFit: 'cover',
										position: 'absolute',
										bottom: '0',
										right: '0',
										borderRadius: '80px 0px 80px 0px',
										zIndex: '2',
									}}
									className='overlay-img'
								/>


							</div>



						</div>
					</Col>
				</Row>
			</div>
		</>

	);
};
