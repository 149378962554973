import React, { useEffect, useState } from "react";
import {
    Container, Card, CardTitle,
    CardImg,
    CardImgOverlay,
    Row, Col
} from "reactstrap";
import { IoFlashSharp } from "react-icons/io5";

import _fetch from '../../_fetch.js';
import './styles.scss';
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../components/Loader/loader.js";





export default function CoreSculptCategories() {
    const location = useLocation();
    const history = useHistory();
    const categoryId = location?.search?.split('=')[1] || "";
    const categoryDesc = location?.state?.description;
    const categoryTitle = location?.state?.name;
    const [loading, setLoading] = useState(true);
    const [focusAreas, setFocusAreas] = useState([])


    const getdata = async () => {
        try {
            let categoryData = await _fetch(`${process.env.REACT_APP_API_V2}/focus-area?category_id=${categoryId}`);
            console.log(categoryData, 'DATACATGEORY')
            if (categoryData.success) {
                setFocusAreas(categoryData.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        getdata();
    }, []);


    if (loading) {
        return (
            <>
                <Container className="mt-7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>
        )
    }


    return (
        <>
            <div id="core-sculpt-focus-area" className="w-100 bg-color mb-4">
                <div className="w-100">
                    <div class='d-flex flex-column justify-content-center align-items-center pt-4 pb-3 px-4'>
                        <h2
                            className="text-center"
                        >{categoryTitle}</h2>
                        <div class='d-flex justify-content-center align-items-center text-center'>
                            <h2>Step 2: Choose your Focus Area</h2>
                        </div>
                        <p className="text-center">{categoryDesc}</p>
                    </div>
                    <Row className="mx-1 mx-md-4">
                        {focusAreas.map((elem, index) => (
                            <Col xs={12} lg={6} className="w-100 my-2 ">
                                <Card style={{
                                    border: 0,
                                    overflow: "hidden",
                                    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                    cursor: 'pointer',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                }}
                                    className="card-border-class"
                                    onMouseOver={(e) => { e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.5)' }}
                                    onMouseOut={(e) => { e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)' }}

                                    onClick={() => {
                                        history.push(`/core-sculpt/${categoryTitle.replace(/[\s/]/g, '-').toLowerCase()}/${elem.name.replace(' ', '-').toLowerCase()}`, {
                                            description: elem.description,
                                            name: elem.name,
                                            focusAreaId: elem._id,
                                            categoryId: elem.category
                                        })
                                    }
                                    }
                                >
                                    <div className="gradient-overlay"></div>
                                    <CardImg
                                        alt="Card image cap"
                                        className="img-height-fixedss"
                                        src={elem.thumbnail}
                                    />
                                    <CardImgOverlay style={{ background: "transparent" }}>
                                        <CardTitle tag="h2" className="text-white card-title" style={{ zIndex: 3, position: 'relative' }}>
                                            {elem.name}
                                        </CardTitle>
                                        <div
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                left: 20,
                                                bottom: 10,
                                                justifyContent: 'space-evenly',
                                                zIndex: 3
                                            }}
                                        >
                                            <IoFlashSharp
                                                size={20}
                                                style={{
                                                    color: "#c58bf2",
                                                }}
                                            />
                                            <IoFlashSharp
                                                size={20}
                                                style={{
                                                    color: "#c58bf2",
                                                }}
                                            />
                                            <IoFlashSharp
                                                size={20}
                                                style={{
                                                    color: "#c58bf2",
                                                }}
                                            />
                                        </div>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </>

    );

};
