import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Button, Container, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Tour from "reactour";
import { useCookies } from "react-cookie";
import _fetch from "../_fetch.js";
import Loader from "../components/Loader/loader.js";
import { getEnrollments, getUserData, LoginAction, LogoutAction } from "../actions/userActions.js";
import { InitializeAction } from "../actions/appActions.js";

import Footer from "../components/Footers/Footer.js";
import Sidebar from "../components/Sidebar/UserSidebar.js";
import UserNavBar from "../components/Navbars/UserNavBar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import AdminNavbar from "../components/Navbars/AdminNavBar";
import Header2 from "../components/Headers/Header2.js";

import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register.js";
import ForgotPassword from "../views/Auth/ForgotPassword";

import Home from "../views/Home";
import Contact from "../views/Contact";
import About from "../views/About";
import Dashboard from "../views/Dashboard";
import Watch from "../views/Watch";
import WorkoutVideoPlayer from "../views/WorkoutVideoPlayer";
import LiveClasses from "../views/LiveClasses/LiveClasses.js";
import Recordings from "../views/Recordings";
import Cart from "../views/Cart";
import Classes from "../views/enrollment";
import DietPackageForm from "../views/DietPackage";
import Testimonial from "../views/Testimonial";
import ClassesDetail from "../views/Classes";
import ClientTransformation from "../views/ClientTransfomation";
import OpenCart from "../views/OpenCart";
import AddRecipe from "../views/Recipe";
import Recipe from "../views/Recipe/view.js";
import MyRecipes from "../views/Recipe/MyRecipes.js";
import AllPublishedRecipes from "../views/Recipe/list.js";
import personalTraining from '../views/PersonalTraining'
import Unsubscribe from "../views/Unsubscribe";
import CreateSubscription from "../views/Subscription/CreateSubscription.js";
import SubscriptionSuccess from "../views/Subscription/SubscriptionSuccess.js";
import Subscription from "../views/Subscription/index.js";
import BookHabitCoach from "../views/HabitCoach/BookHabitCoach.js";
import HabitCoachSuccess from "../views/HabitCoach/HabitCoachSuccess.js";
import AppScreenData from "../views/Admin/AppScreenData/index.js";
import AllSubscribedUsers from "../views/Admin/SubscribedUsers/index.js";
import InterestedUsers from "../views/Admin/InterestedUsers/index.js";
import ManageAffilations from "../views/Admin/ManageAffilations/index.js";
import Packages2 from "../views/Packages/index2.js";
import CoreSculpt from "../views/CoreSculpt/index.js";
import CoreSculptCategories from "../views/CSCategoriesDetails/index.js";
import Affilations from "../views/Affilations/index.js";
import FocusAreaDetails from "../views/CSFocusAreaDetails/index.js";
import CSVideoPlayer from "../views/CSVideoPlayer/index.js";
import DeleteMyAccount from "../views/DeleteMyAccount/index.js";


const Support = React.lazy(() => import("../views/Support"));
const Profile = React.lazy(() => import("../views/Profile"));



const TestimonialForm = React.lazy(() => import("../views/Admin/WebContent/testimonial.js"));
const TransformationForm = React.lazy(() => import("../views/Admin/WebContent/transformation.js"));
const WebContentForm = React.lazy(() => import("../views/Admin/WebContent/webContent.js"));
const Enrollments = React.lazy(() => import("../views/Admin/Enrollments"));
const Questions = React.lazy(() => import("../views/Admin/Questions"));
const AllRecipes = React.lazy(() => import("../views/Admin/Recipe"));
const SixtyMinNSPCall = React.lazy(() => import("../views/Admin/60MinEnrollments"));
const SixWeekNSPEnrollments = React.lazy(() => import("../views/Admin/6WeekEnrollments"));
const AddClass = React.lazy(() => import("../views/Admin/AddClass"));
const AllClasses = React.lazy(() => import("../views/Admin/AllClasses"));
const AddCategory = React.lazy(() => import("../views/Admin/AddCategory"));
const AppCategory = React.lazy(() => import("../views/Admin/AppCategory"));
const AddFocusArea = React.lazy(() => import("../views/Admin/AddFocusArea"));
const ActiveUsers = React.lazy(() => import("../views/Admin/ActiveUsers"));
const HabitCoaches = React.lazy(() => import("../views/Admin/HabitCoaches"));
const AdminAppCoupons = React.lazy(() => import("../views/Admin/AppCoupons"));
const BulkClassUpload = React.lazy(() => import("../views/Admin/BulkClassUpload"));
const RefundAndCancellationPolicy = React.lazy(() => import("../views/RefundPolicy"));
const Notification = React.lazy(() => import("../views/Admin/Notification"));


const Reset = React.lazy(() => import('../views/Auth/ResetPassword'));
const Privacy = React.lazy(() => import('../views/PrivacyPolicy/index.js'));
const TermsAndConditions = React.lazy(() => import('../views/TermsAndConditions/index.js'));


const Wrapper = ({ Component, mainContent }) => {

	useEffect(() => {
		document.body.classList.add("bg-default");
		return () => {
			document.body.classList.remove("bg-default");
		};
	}, []);

	return (
		<>
			<div className="main-content landing-site" ref={mainContent}>
				<Header2 />
				{/* Page content */}
				<Container className="my-5 my-xl-8">
					<Row className="justify-content-center">
						<Component />
					</Row>
				</Container>
			</div>
			<AuthFooter />
		</>
	)
}

const Wrapper2 = ({ Component, mainContent }) => {
	return (
		<div
			className='w-100 landing-site'
			style={{ background: '#fafafa', minHeight: '100vh', scrollBehavior: 'smooth' }}
			ref={mainContent}
		>
			<Component />
		</div>
	)
}

const AuthWrapper = ({ Component, mainContent, hideUserNavBar = false }) => {
	const { _id } = useSelector(st => st.user);

	if (!_id) return <Redirect to='/' />

	return (
		<React.Fragment>
			<Sidebar
				logo={{
					innerLink: "/",
					imgSrc: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`,
					imgAlt: "Keep Well Mantras",
				}}
			/>
			<div className="main-content" ref={mainContent}>
				{
					!hideUserNavBar && <UserNavBar />
				}
				<Component />
				<Container fluid>
					<Footer />
				</Container>
			</div>
		</React.Fragment>
	)
}

const AuthAdminWrapper = ({ Component, mainContent }) => {
	const { _id } = useSelector(st => st.user);

	if (!_id) return <Redirect to='/' />

	return (
		<React.Fragment>
			<Sidebar
				logo={{
					innerLink: "/",
					imgSrc: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`,
					imgAlt: "Keep Well Mantras",
				}}
			/>
			<div className="main-content" ref={mainContent}>
				<AdminNavbar />
				<Component />
				<Container fluid>
					<Footer />
				</Container>
			</div>
		</React.Fragment>
	)
}

const steps = [{
	selector: '.stats-card-1',
	content: 'Click here to update your current weight on your profile. Update every 2 weeks!'
}, {
	selector: '.stats-card-2',
	content: 'Click here to update your current waist on your profile. Update every 2 weeks!'
}, {
	selector: '.stats-card-3',
	content: 'The no. of live classes available out of total booked. Click here to book and attend the LIVE classes & their recordings from the calendar'
}, {
	selector: '.stats-card-4',
	content: 'The no. of workout recordings available out of total booked. Click here to book and access your workouts'
}, {
	selector: '.live-calendar',
	content: 'The calendar shows all current and upcoming live classes. You can either join the classes live or watch their recordings. No prior booking needed'
}, {
	selector: '.live-class-checkout',
	content: 'Click here to go to your cart and buy live classes'
}, {
	selector: '.cat-card',
	content: 'You can create your own package by selecting from a list of goal specific workouts and adding them to your cart.  If you need help in creating a package, get in touch.'
}, {
	selector: '.recorded-class-checkout',
	content: 'Use this button to check out and buy your selection of workout videos'
}, {
	selector: '.my-recorded-class',
	content: 'Click here to see the list of all workout videos purchased'
}, {
	selector: '.nav-profile',
	content: 'Click here to view and update your profile and a track of your activity'
}, {
	selector: '.nav-contact',
	content: 'Click here to contact us for any further support'
}, {
	selector: '.nav-tour',
	content: 'To take this tour again, click here!'
}];

const UserLayout = () => {

	const mainContent = React.useRef(null);
	const location = useLocation();
	const searchParams = new URLSearchParams(location?.search);
	const jwtToken = searchParams.get('jwt_token');
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [cookie, setCookie] = useCookies(['tour', 'jwt']);

	const { admin, _id, first_name, last_name } = useSelector(st => st.user);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if (mainContent?.current) mainContent.current.scrollTop = 0;
		if (!cookie.tour) setCookie('tour', 'started');
		if (jwtToken && !cookie.jwt) {
			setCookie('jwt', jwtToken, { maxAge: process.env.REACT_APP_ACCESS_TOKEN_EXPIRY_IN_DAYS * 86400000 });
		}
		const init = async () => {
			try {
				let data = await getUserData();
				if (data) {
					dispatch(LoginAction(data));
					await getEnrollments(dispatch);
				} else {
					dispatch(LogoutAction);
				}
				let web_content = await _fetch(`${process.env.REACT_APP_API}/`);
				dispatch(InitializeAction(web_content.success ? web_content.response : {}));

			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}
		init();
	}, [cookie.tour, setCookie, jwtToken, cookie.jwt, dispatch]);

	const [step, setStep] = useState(0);
	let history = useHistory();

	function next() {
		switch (step) {
			case 4:
				history.push('/live-sessions');
				break;
			case 6:
				history.push('/recorded-sessions');
				break;
			default:
				break;
		}
		setStep(step + 1);
	}

	function end() {
		setStep(0);
		setCookie('tour', 'completed', { maxAge: 10 * 365 * 24 * 3600 })
	}

	if (loading) {
		return (
			<div className='h-100 w-100 d-flex'>
				<Loader />
			</div>
		)
	}

	return (
		<>

			<Switch>

				{/* un authenticated */}

				{/* <Route path='/test' exact>
					<Test />
				</Route> */}
				<Route path='/dashboard' exact>
					<AuthWrapper Component={Dashboard} />
				</Route>
				<Route path={['/', '/home']} exact>
					<Wrapper2 Component={Home} mainContent={mainContent} />
				</Route>
				<Route path='/unsubscribe' exact>
					<Wrapper Component={Unsubscribe} mainContent={mainContent} />
				</Route>
				<Route path='/coresculptapp' exact>
					<Subscription />
				</Route>
				<Route path='/subscription/create/:id' exact>
					<CreateSubscription />
				</Route>
				<Route path='/subscription/success' exact>
					<SubscriptionSuccess />
				</Route>
				<Route path='/habit-coach/book' exact>
					<BookHabitCoach />
				</Route>
				<Route path='/habit-coach/success' exact>
					<HabitCoachSuccess />
				</Route>
				<Route path='/register' exact>
					{
						_id ?
							<AuthWrapper Component={Dashboard} />
							:
							<Wrapper Component={Register} mainContent={mainContent} />
					}
				</Route>
				<Route path='/reset_pass' exact>
					{
						_id ?
							<AuthWrapper Component={Dashboard} />
							:
							<Wrapper Component={Reset} mainContent={mainContent} />
					}
				</Route>
				<Route path='/forgot-password' exact>
					{
						_id ?
							<AuthWrapper Component={Dashboard} />
							:
							<Wrapper Component={ForgotPassword} mainContent={mainContent} />
					}
				</Route>
				<Route path='/login' exact>
					{
						_id ?
							<AuthWrapper Component={Dashboard} />
							:
							<Wrapper Component={Login} mainContent={mainContent} />
					}
				</Route>
				<Route path='/about' exact>
					<Wrapper2 Component={About} mainContent={mainContent} />
				</Route>
				<Route path='/privacy' exact>
					<Wrapper2 Component={Privacy} mainContent={mainContent} />
				</Route>
				<Route path='/refund' exact>
					<Wrapper2 Component={RefundAndCancellationPolicy} mainContent={mainContent} />
				</Route>
				<Route path='/terms' exact>
					<Wrapper2 Component={TermsAndConditions} mainContent={mainContent} />
				</Route>
				<Route path='/contact' exact>
					<Wrapper2 Component={Contact} mainContent={mainContent} />
				</Route>
				<Route path='/testimonials' exact>
					<Wrapper2 Component={Testimonial} mainContent={mainContent} />
				</Route>
				{/* <Route path='/packages' exact>
					<Wrapper2 Component={Packages} mainContent={mainContent} />
				</Route> */}
				<Route path='/packages' exact>
					<Wrapper2 Component={Packages2} mainContent={mainContent} />
				</Route>
				<Route path='/classes' exact>
					<Wrapper2 Component={ClassesDetail} mainContent={mainContent} />
				</Route>
				<Route path='/client-transformation' exact>
					<Wrapper2 Component={ClientTransformation} mainContent={mainContent} />
				</Route>
				<Route path='/cart' exact>
					<Wrapper2 Component={OpenCart} mainContent={mainContent} />
				</Route>
				<Route path='/recipes' exact>
					<Wrapper2 Component={AllPublishedRecipes} mainContent={mainContent} />
				</Route>
				<Route path='/recipe' exact>
					<Wrapper2 Component={Recipe} mainContent={mainContent} />
				</Route>
				<Route path='/affilations' exact>
					<Wrapper2 Component={Affilations} mainContent={mainContent} />
				</Route>
				<Route path='/delete-my-account' exact>
					<Wrapper Component={DeleteMyAccount} mainContent={mainContent} />
				</Route>

				{/* authenticated */}
				<Route path='/profile' exact>
					<AuthWrapper Component={Profile} mainContent={mainContent} hideUserNavBar={true} />
				</Route>
				<Route path='/live-sessions' exact>
					<AuthWrapper Component={LiveClasses} mainContent={mainContent} />
				</Route>
				<Route path='/recorded-sessions' exact>
					<AuthWrapper Component={Recordings} mainContent={mainContent} />
				</Route>
				<Route path='/core-sculpt' exact>
					<AuthWrapper Component={CoreSculpt} mainContent={mainContent} hideUserNavBar={true} />
				</Route>
				<Route path='/core-sculpt/video/:id' exact>
					<AuthWrapper Component={CSVideoPlayer} mainContent={mainContent} hideUserNavBar={true} />
				</Route>
				<Route path='/core-sculpt/:category' exact>
					<AuthWrapper Component={CoreSculptCategories} mainContent={mainContent} hideUserNavBar={true} />
				</Route>
				<Route path='/core-sculpt/:category/:focusarea' exact>
					<AuthWrapper Component={FocusAreaDetails} mainContent={mainContent} hideUserNavBar={true} />
				</Route>
				<Route path='/buy-classes' exact>
					<AuthWrapper Component={Cart} mainContent={mainContent} />
				</Route>
				<Route path='/watch' exact>
					<AuthWrapper Component={Watch} mainContent={mainContent} />
				</Route>
				<Route path='/workout/:id' exact>
					<AuthWrapper Component={WorkoutVideoPlayer} mainContent={mainContent} />
				</Route>
				<Route path='/classes/:id' exact>
					<AuthWrapper Component={Classes} mainContent={mainContent} />
				</Route>
				<Route path='/contact-us' exact>
					<AuthWrapper Component={Support} mainContent={mainContent} />
				</Route>
				<Route path='/diet-package' exact>
					<AuthWrapper Component={DietPackageForm} mainContent={mainContent} />
				</Route>
				<Route path='/personal-training' exact>
					<AuthWrapper Component={personalTraining} mainContent={mainContent} />
				</Route>
				<Route path='/add_recipe'>
					<AuthWrapper Component={AddRecipe} mainContent={mainContent} />
				</Route>
				<Route path='/my_recipes'>
					<AuthWrapper Component={MyRecipes} mainContent={mainContent} />
				</Route>
				<Route path='/admin'>
					{
						admin ?
							<Switch>
								<Route path={['/admin/add-class', '/admin/add-class/:id']} exact>
									<AuthAdminWrapper Component={AddClass} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/classes'} exact>
									<AuthAdminWrapper Component={AllClasses} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/questions'} exact>
									<AuthAdminWrapper Component={Questions} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/6-week-enrollments'} exact>
									<AuthAdminWrapper Component={SixWeekNSPEnrollments} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/60-min-enrollments'} exact>
									<AuthAdminWrapper Component={SixtyMinNSPCall} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/all_recipes'} exact>
									<AuthAdminWrapper Component={AllRecipes} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/testimonials'} exact>
									<AuthAdminWrapper Component={TestimonialForm} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/transformations'} exact>
									<AuthAdminWrapper Component={TransformationForm} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/web_content'} exact>
									<AuthAdminWrapper Component={WebContentForm} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/enrollments'} exact>
									<AuthAdminWrapper Component={Enrollments} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/app-category'} exact>
									<AuthAdminWrapper Component={AppCategory} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/add-category'} exact>
									<AuthAdminWrapper Component={AddCategory} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/add-category/:id'} exact>
									<AuthAdminWrapper Component={AddCategory} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/add-focus-area'} exact>
									<AuthAdminWrapper Component={AddFocusArea} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/add-focus-area/:id'} exact>
									<AuthAdminWrapper Component={AddFocusArea} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/active_users'} exact>
									<AuthAdminWrapper Component={ActiveUsers} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/habit-coaches'} exact>
									<AuthAdminWrapper Component={HabitCoaches} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/notification'} exact>
									<AuthAdminWrapper Component={Notification} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/bulk-class-upload'} exact>
									<AuthAdminWrapper Component={BulkClassUpload} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/app-screen-data'} exact>
									<AuthAdminWrapper Component={AppScreenData} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/subscribed-users'} exact>
									<AuthAdminWrapper Component={AllSubscribedUsers} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/interested-users'} exact>
									<AuthAdminWrapper Component={InterestedUsers} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/app-coupons'} exact>
									<AuthAdminWrapper Component={AdminAppCoupons} mainContent={mainContent} />
								</Route>
								<Route path={'/admin/affilations'} exact>
									<AuthAdminWrapper Component={ManageAffilations} mainContent={mainContent} />
								</Route>
							</Switch>
							:
							<AuthWrapper Component={Dashboard} />
					}
				</Route>
				<Route path='*'>
					{
						_id ?
							<AuthWrapper Component={Dashboard} />
							:
							<Home />
					}
				</Route>
			</Switch>

			<Tour
				steps={steps}
				isOpen={cookie.tour === 'started' && (step === 0 ? history.location.pathname === '/dashboard' : true)}
				onRequestClose={end}
				nextStep={next}
				goToStep={step - 1}
				startAt={0}
				disableDotsNavigation={true}
				lastStepNextButton={<Button color='primary' size='sm' onClick={end}>End Tour</Button>}
				showNavigation={false}
				className='text-dark'
			/>

			<Modal isOpen={history.location.pathname === '/dashboard' && !cookie?.tour} toggle={() => setCookie('tour', 'completed')}>
				<ModalBody>
					<p className='font-weight-500'>
						Thankyou for signing up {first_name} {last_name}! Welcome to your personal dashboard Fitness Stars. Do you wish to take a website tour?
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color='danger' onClick={() => setCookie('tour', 'completed')}>No</Button>
					<Button
						color='primary'
						onClick={() => {
							history.push('/dashboard');
							setCookie('tour', 'started');
						}}
					>
						Yes
					</Button>
				</ModalFooter>
			</Modal>

		</>
	);
};

export default UserLayout;
