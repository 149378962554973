import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import {
	Row,
	Col,
	Modal,
	ModalBody,
	Form,
	FormGroup,
	Input,
	Button,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import validator from 'validator';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { BsCheckCircle } from 'react-icons/bs';
import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import { AiOutlineUser, AiOutlineClose } from 'react-icons/ai';
import _fetch from '../../_fetch';
import AboutCoachSlider from '../../components/Sliders/AboutCoachSlider';
import leftTwigSVG from '../../assets/subscription/flower-1.svg';
import rightTwigSVG from '../../assets/subscription/flower-2.svg';
import ellipsisBG from '../../assets/home-page/svgs/ellipsis-bg.svg';
import bannerSVG from '../../assets/home-page/svgs/banner.svg';
import specilitesIMG from '../../assets/home-page/images/specialities-img.png';
import promotiomIMG from '../../assets/home-page/images/promotion-img.png';

import './styles.scss';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/loader';

export default function Home() {
	const [cookies, setCookie] = useCookies([
		'cart',
		'live_class_count',
		'isNewletterFilled',
	]);
	let history = useHistory();

	var settings = {
		infinite: true,
		speed: 500,
		focusOnSelect: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					infinite: true,
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		//customly render images
	};

	const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
	const [isNewletterModalAck, setIsNewletterModalAck] = useState(false);
	const [interestedUser, setInterestedUser] = useState({
		name: '',
		email: '',
		interestedFor: 'Newsletter',
	});
	const [_error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const testimonials = useSelector((st) => st.app.testimonials);
	const modalSectionRef = useRef(null);

	async function addInterestedUser(ev) {
		ev.preventDefault();
		try {
			setLoading(true);
			if (!interestedUser.name) setError('Please enter your name');
			else if (
				!interestedUser.email ||
				!validator.isEmail(interestedUser.email)
			)
				setError('Please enter valid email id');
			else {
				let res = await _fetch(
					process.env.REACT_APP_API_V2 + '/interested-user',
					{
						method: 'POST',
						body: {
							...interestedUser,
							sendEmail: false,
						},
					}
				);
				if (res.success) {
					toast.success(
						'Thank you for subscribing. You are now a part of our KeepWell Mantras Insider community!'
					);
					setInterestedUser({
						name: '',
						email: '',
						interestedFor: 'Newsletter',
					});
					setError('');
					setIsNewsletterModalOpen(false);
					setCookie('isNewletterFilled', 'true');
				} else {
					setError(res.response);
				}
			}
		} catch (error) {
			console.log(error);
			setError('Some error occurred please try again later.');
		} finally {
			setLoading(false);
		}
	}

	const transformation_pics = useSelector((st) => st.app.transformation_pics);
	const award_pics = [
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award1.jpg`,
			caption:
				'Outstanding Achievements in Business 2023 – Awarded by Honorable MP Bob Blackman, UK Parliament in collaboration with One Elements Organization',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award.jpg`,
			caption:
				"'Remarkable Mentor’ by SheInspires Awards – May 2022 & 2023, awarded twice consecutively at UK Parliament by Honorable MP Bob Blackman",
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award2.jpeg`,
			caption:
				'Community Service award – Nov 22, At South Asian Women Leader Conference awarded by OneVision charity in the presence of Honorable MP Dean Russel, President of SAHA & Chief Inspector Police',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award4.jpg`,
			caption:
				'Business Community Leader – June 23 by EEL Professional Association for mentoring & supporting fellow small businesses.',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award3.jpeg`,
			caption:
				'British Fitness Awards – On the shortlist of top 25 Fitness Trainers of UK and KeepWell Mantras on the shortlist of Top 10 Sports groups of UK among circa 23000+ nominees',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award6.jpeg`,
			caption:
				'Mrs. & Ms. Asia Great Britain - Fitness mentor & Community engagement Lead for Beauty pageants',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award5.jpeg`,
			caption:
				'Frames of Mind, Newham Council – Regular volunteering for movement sessions for the Elderly',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award7.jpeg`,
			caption:
				'PerksAtWork –  One of the chosen Health & Wellness partners of a leading Corporate Employee Benefits company NextJump',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award8.jpeg`,
			caption: 'SME British Made Awards 2023 - Best Fitness Entrepreneur',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award9.jpeg`,
			caption: 'Health & Wellbeing 2024 - UK Parliament',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/region-chair.jpeg`,
			caption: 'London Region Chair in Health & Wellness for G100',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/interview.jpeg`,
			caption: 'Interview published in the leading newspaper AsianVoice',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/interview2.jpeg`,
			caption: 'Interview published in the leading newspaper AsianVoice',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/PHOTO-2024-03-25-20-11-36.jpg`,
			caption: 'Winner - Health & Fitness 2024 She Awards',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/women_impact_award.jpeg`,
			caption: 'Women of Wonder Award  by Panache',
		},
		{
			img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/women_wonder_award.jpeg`,
			caption:
				'Women of Impact award by MP Virendra Sharma and Sunil Chopra, Mayor of Southwark',
		},
	];

	const servicesData = [
		{
			image: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Product-Section-Img-1.jpg`,
			title: 'Live Classes & Recordings',
			subTitle:
				'Join live sessions or catch up anytime with our on-demand recordings.',
			onClick: () => {},
		},
		{
			image: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Product-Section-Img-2.jpg`,
			title: 'Core Sculpt App',
			subTitle: 'Sculpt your core with our cutting-edge fitness app.',
			onClick: () => {},
		},
		{
			image: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Product-Section-Img-3.jpg`,
			title: 'Nutritional Plan',
			subTitle: 'Fuel your fitness journey with personalized nutrition plans.',
			onClick: () => {},
		},
		{
			image: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Product-Section-Img-4.jpg`,
			title: 'Habit & Mindset Coaching',
			subTitle: 'Transform your habits for lasting health and wellness.',
			onClick: () => {},
		},
	];

	const specialitiesData = [
		{
			heading: 'CoreSculpt App',
			text: 'Sculpt your core with our cutting-edge fitness app.',
		},
		{
			heading: 'Live Classes & Recordings',
			text: 'Join live sessions or catch up anytime with our on-demand recordings.',
		},
		{
			heading: 'Nutrition Plans',
			text: 'Fuel your fitness journey with personalized nutrition plans.',
		},
		{
			heading: 'Habit & Mindset Coaching',
			text: 'Transform your habits for lasting health and wellness.',
		},
		{
			heading: 'Personal Training',
			text: 'Get one-on-one guidance to reach your fitness goals.',
		},
		{
			heading: 'Combo offers',
			text: 'Save big with our bundled fitness packages.',
		},
		{
			heading: 'Customise my offering',
			text: 'Tailor your fitness experience to your unique needs and preferences.',
		},
	];

	const ServiceCard = ({ heading, text, index, isInEllipsis = false }) => {
		if (isInEllipsis) {
			return (
				<div
					key={index}
					className={`d-none d-lg-flex ${
						index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
					} service-card service-card-ind-${index}`}
				>
					<div>
						<h4
							className={`service-card-heading ${
								index % 2 === 0 ? 'text-right' : 'text-left'
							}`}
						>
							{heading}
						</h4>
						<p
							className={`service-card-text ${
								index % 2 === 0 ? 'text-right' : 'text-left'
							}`}
						>
							{text}
						</p>
					</div>
					<div className={`service-card-icon-container`}>
						<BsCheckCircle
							color='white'
							size={30}
							className='service-card-icon'
						/>
					</div>
				</div>
			);
		} else {
			return (
				<Col
					xs={12}
					sm={6}
					key={index}
					className={`d-flex ${
						index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
					} service-card my-2`}
				>
					<div>
						<h4
							className={`service-card-heading ${
								index % 2 === 0 ? 'text-right' : 'text-left'
							}`}
						>
							{heading}
						</h4>
						<p
							className={`service-card-text ${
								index % 2 === 0 ? 'text-right' : 'text-left'
							}`}
						>
							{text}
						</p>
					</div>
					<div className={`service-card-icon-container`}>
						<BsCheckCircle
							color='white'
							size={30}
							className='service-card-icon'
						/>
					</div>
				</Col>
			);
		}
	};

	const TestimonialCard = ({ testimonial, name }) => (
		<div className='testimonial-card d-flex flex-column p-3 my-3'>
			<p className='testimonial-card-text'>
				{testimonial.length > 100
					? `${testimonial.substring(0, 100)}...`
					: testimonial}
			</p>
			<div className='d-flex flex-row align-items-center testimonial-card-footer'>
				<AiOutlineUser
					size={35}
					className='testimonial-card-icon'
					color='white'
				/>
				<div>
					<h4 className='testimonial-card-name'>{name}</h4>
					<p className='testimonial-card-subtext'>Happy Client</p>
				</div>
			</div>
		</div>
	);

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.2,
		};
		const callback = (entries) => {
			entries.forEach((entry) => {
				const isNewletterFilled =
					cookies.isNewletterFilled && cookies.isNewletterFilled === 'true';
				if (
					entry.isIntersecting &&
					!isNewletterModalAck &&
					!isNewletterFilled
				) {
					setIsNewsletterModalOpen(true);
					setIsNewletterModalAck(true);
				}
			});
		};
		const observer = new IntersectionObserver(callback, options);
		if (modalSectionRef.current) {
			observer.observe(modalSectionRef.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [isNewletterModalAck]);

	return (
		<div id='home-page'>
			<img src={leftTwigSVG} alt='..' className='left-twig-svg' />
			<Header2 />

			<div
				id='banner-page'
				className='d-flex align-items-center justify-content-center flex-column'
			>
				<img src={rightTwigSVG} alt='..' className='right-twig-svg' />
				<div className='container banner-container h-80'>
					<Row>
						<Col
							xs={12}
							lg={6}
							className='content-holder-col d-flex flex-column justify-content-center'
						>
							<div className='d-flex flex-column justify-content-center align-items-center'>
								<h3 className='banner-tagline'>
									Making wellness an unfaltering habit !
								</h3>
							</div>
							<h2 className='banner-heading mt-2'>
								<span
									style={{
										color: '#c0a6cc',
									}}
								>
									Prapti Dutt
								</span>
								{`, a multi award-winning\nFitness Trainer &\nNutritionist`}
							</h2>
							<h3 className='banner-subheading mt-2'>
						{`Founder of KeepWell Mantras & CoreSculpt Mobile App\n\nExpert in crafting innovative at-home workouts!\nCore Pilates | Strength training | HIITs | Customised Weight Loss Plans | Mindset mentor`}
							</h3>
							<div className='d-flex flex-column flex-md-row mt-4 buttons-container'>
								<button className='banner-button w-100 w-md-auto'>
									<Link to='/login'>Access Live Classes</Link>
								</button>
								<button
									className='banner-button w-100 w-md-auto'
									onClick={() => {
										window.open(
											'https://play.google.com/store/apps/details?id=com.keepwellmantras',
											'_blank'
										);
									}}
								>
									Download Android App
								</button>
								<button
									className='banner-button w-100 w-md-auto'
									onClick={() => {
										window.open(
											'https://apps.apple.com/us/app/coresculpt/id6450386191',
											'_blank'
										);
									}}
								>
									Download iOS App
								</button>
							</div>
						</Col>
						<Col
							xs={12}
							lg={6}
							className='image-holder-col d-flex align-items-center justify-content-center'
						>
							<div className='banner-image-wrapper'>
								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Website+home+page.jpg`}
									className='banner-image'
									alt='..'
								/>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div
				id='specialities-page'
				className='d-flex align-items-center justify-content-center flex-column'
			>
				<img src={leftTwigSVG} alt='..' className='left-twig-svg' />
				<img src={rightTwigSVG} alt='..' className='right-twig-svg' />
				<div className='container specialities-container'>
					<h2 className='section-heading mt-2'>My Services</h2>
					<Row>
						<Col
							xs={12}
							className='d-flex flex-column align-items-center justify-content-center mt-3'
						>
							<div
								style={{
									backgroundImage: `url(${ellipsisBG})`,
								}}
								className='image-container-ellipsis'
							>
								<img
									src={specilitesIMG}
									alt='..'
									className='image-ellipsis img-fluid'
								/>
								{specialitiesData.map((speciality, index) => (
									<ServiceCard
										heading={speciality.heading}
										text={speciality.text}
										index={index}
										isInEllipsis={true}
									/>
								))}
							</div>
						</Col>
					</Row>
					<Row className='d-lg-none mt-4'>
						{specialitiesData.map((speciality, index) => (
							<ServiceCard
								heading={speciality.heading}
								text={speciality.text}
								index={index}
							/>
						))}
					</Row>
				</div>
			</div>
			<div
				id='about-page'
				className='d-flex align-items-center justify-content-center flex-column'
				ref={modalSectionRef}
			>
				<img src={leftTwigSVG} alt='..' className='left-twig-svg' />
				<img src={rightTwigSVG} alt='..' className='right-twig-svg' />
				<div className='container about-container'>
					<h3 className='section-small-heading'>About Your Coach</h3>
					<h2 className='section-heading mt-2'>Awards & Accolades</h2>
					<h3 className='section-subheading mt-2 mb-4'>
						Introducing Prapti Dutt: A London-based, multi-award-winning fitness
						transformation coach. Certified as a Level 4 trainer in Strength &
						Conditioning, Level 3 in Pilates, Level 3 in Pre/Post pregnancy
						trainer and Level 3 Nutritionist. Prapti's expertise knows no
						bounds. Hailing from the heart of the UK, her Online Fitness &
						Nutrition program resonates globally, focusing on enhancing core
						strength. Beyond weight loss, Prapti inspires men and women to forge
						a stronger self through Bodyweight and equipment based strength
						training at-home workouts.
					</h3>
					<Row>
						<Col xs={12}>
							<AboutCoachSlider settings={settings} images={award_pics} />
						</Col>
					</Row>
				</div>
			</div>
			<div
				id='fitness-stars-page'
				className='d-flex align-items-center justify-content-center flex-column'
			>
				<div className='container fitness-stars-container'>
					<h2 className='section-heading mt-2'>My Rockstars</h2>
					<h3 className='section-subheading mt-2 mb-4'>
						Explore the incredible transformation journey of our dedicated
						fitness enthusiasts. These transformation pics speak volumes about
						their hard work, determination, and the remarkable results achieved
						through our fitness programs. Get inspired and join us in your own
						fitness transformation today!
					</h3>
					<Row>
						<Col xs={12}>
							<Slider {...settings}>
								{transformation_pics.map((e) => (
									<div key={e.img} className='px-2'>
										<img
											src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${e.img}`}
											alt=''
											className='img img-fluid slick-carousel-image'
										/>
									</div>
								))}
							</Slider>
						</Col>
					</Row>
				</div>
			</div>
			<div
				id='services-page'
				className='d-flex align-items-center justify-content-center flex-column'
			>
				<img src={leftTwigSVG} alt='..' className='left-twig-svg' />
				<img src={rightTwigSVG} alt='..' className='right-twig-svg' />
				<div className='container services-container'>
					<h2 className='section-heading mt-2'>
						{`Practice Wherever You Want \nWhenever You Need`}
					</h2>
					<h3 className='section-subheading mt-2 mb-4'>
						Corestrength | Online Fitness classes | Customised Nutrition |
						Personal Training | Habit & Mindset coaching
					</h3>
					<Row>
						{servicesData.map((service, index) => (
							<Col
								xs={6}
								lg={3}
								className='d-flex flex-column service-card'
								onClick={() => {
									history.push('/packages');
								}}
								style={{
									cursor: 'pointer',
								}}
							>
								<img src={service.image} alt='..' className='service-image' />
								<h4 className='service-heading mt-3'>{service.title}</h4>
								<p className='service-text'>{service.subTitle}</p>
							</Col>
						))}
					</Row>
				</div>
			</div>

			{/* <div
				id='pricing-page'
				className='d-flex align-items-center justify-content-center flex-column'
			>
				<img
					src={leftTwigSVG}
					alt='..'
					className='left-twig-svg'
				/>
				<img
					src={rightTwigSVG}
					alt='..'
					className='right-twig-svg'
				/>
				<div
					className='container pricing-container'
				>
					<h3
						className='section-small-heading'
					>
						WHAT WE OFFER
					</h3>
					<h2
						className='section-heading mt-2'
					>
						Our Pricing Plans
					</h2>
					<h3
						className='section-subheading mt-2 mb-4'
					>
						Our pricing reflects our unwavering commitment to delivering exceptional quality, ensuring you get the best value for your investment.
					</h3>
					<Row

					>
						{
							packagesData.map((product, index) => (
								<ProductCard
									product={product}
								/>
							))
						}
					</Row>
					<div
						className='d-flex justify-content-center'
					>
						<button
							className='more-packages-btn mt-5'
						>
							<Link to="/packages"
								style={{
									color: '#fff',
									textDecoration: 'none'
								}}
							>
								More Packages
							</Link>
						</button>

					</div>
				</div>
			</div> */}
			<div
				id='testimonial-page'
				className='d-flex align-items-center justify-content-center flex-column flex-lg-row'
			>
				<img src={leftTwigSVG} alt='..' className='left-twig-svg' />
				<img src={rightTwigSVG} alt='..' className='right-twig-svg' />
				<Row>
					<Col
						xs={12}
						lg={6}
						className='d-flex align-items-center justify-content-center flex-column'
					>
						<div className='container testimonial-container'>
							<h3 className='section-small-heading text-lg-left'>
								Testimonials
							</h3>
							<h2 className='section-heading text-lg-left mt-2'>
								What my Rockstars say
							</h2>
							<h3 className='section-subheading text-lg-left mt-2 mb-4'>
								Client testimonials speak volumes about our exceptional service
								and unwavering dedication, echoing the positive impact we bring
								to their experiences.
							</h3>
							<div className='d-flex align-items-center justify-content-center justify-content-lg-start testimonial-stats-container'>
								{/* <div
									className='testimonial-small-imgs-container d-flex align-items-center'
								>
									{
										testimonialSmallImages.map((e, index) => (
											<div
												key={index}
												className='testimonial-small-imgs-wrapper'
												style={{
													marginLeft: index === 0 ? '0px' : '-20px'
												}}
											>
												<img
													src={e}
													alt='..'
													className='testimonial-img'
												/>
											</div>
										))
									}
								</div> */}
								<div className='d-flex flex-column justify-content-center'>
									<h3 className='testimonial-stats-heading'>1k+</h3>
									<p className='testimonial-stats-subheading'>Happy Clients</p>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} lg={6} className='testimonial-card-col-container px-5'>
						<Row>
							<Col
								xs={12}
								lg={6}
								style={{
									paddingTop: '60px',
								}}
							>
								{testimonials.slice(0, 2).map((e, index) => (
									<TestimonialCard testimonial={e.content_1} name={e.name} />
								))}
								<div className='empty-card-div my-3' />
							</Col>
							<Col
								xs={0}
								style={{
									paddingTop: '20px',
								}}
							>
								{testimonials.slice(3, 5).map((e, index) => (
									<TestimonialCard testimonial={e.content_1} name={e.name} />
								))}
								<div className='empty-card-div my-3' />
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<div
				id='promotion-page'
				className='d-flex flex-column'
				style={{
					backgroundImage: `url(${bannerSVG})`,
				}}
			>
				<div className='container promotion-container d-flex flex-column flex-grow-1'>
					<Row className='flex-grow-1'>
						<Col
							xs={6}
							lg={6}
							className='d-flex flex-column justify-content-center'
						>
							<h2 className='section-heading text-left'>
								{`Escape the mundane and\nembrace the extraordinary!\nUnleash your Iron Core Strength with us !`}
							</h2>
							<button
								className='book-now-btn mt-5'
								onClick={() => {
									history.push('/packages');
								}}
							>
								Book Now
							</button>
						</Col>
						<Col
							xs={6}
							lg={6}
							className='d-flex align-items-end justify-content-center'
						>
							<img src={promotiomIMG} alt='..' className='promotion-img' />
						</Col>
					</Row>
				</div>
			</div>
			<Footer />
			<Modal
				isOpen={isNewsletterModalOpen}
				toggle={() => {
					setIsNewsletterModalOpen(!isNewsletterModalOpen);
				}}
				className='newsletter-modal'
				size='lg'
				centered
			>
				<ModalBody>
					<div className='d-flex justify-content-end'>
						<AiOutlineClose
							onClick={() => setIsNewsletterModalOpen(false)}
							style={{
								cursor: 'pointer',
							}}
							size={20}
						/>
					</div>
					<Row className='content-container-row px-4 px-md-0'>
						<Col
							xs='12'
							md='6'
							className='d-flex flex-column justify-content-center align-items-center'
						>
							<h3
								style={{
									fontWeight: '600',
									fontFamily: 'Forum, cursive',
								}}
								className='my-3'
							>
								Sign up to our Newsletters
							</h3>
							<Form className='w-100' onSubmit={addInterestedUser}>
								<React.Fragment>
									<p
										style={{
											fontSize: 14,
											fontWeight: 400,
											color: '#212529',
											marginBottom: 20,
											textAlign: 'center',
										}}
									>
										Our newsletters are just twice a month sharing things you
										actually would love to know - exclusive discounts, special
										challenges by Prapti, nutrition guidance, healthy recipes,
										Q&As, tips, tricks & and much more Mantras to Keep Well.
									</p>
									<FormGroup className='my-2 w-100'>
										<Input
											type='text'
											name='name'
											id='name'
											placeholder='Name'
											onChange={(e) => {
												setInterestedUser({
													...interestedUser,
													name: e.target.value,
												});
											}}
											className='common-field-box'
											value={interestedUser.name}
										/>
									</FormGroup>
									<FormGroup className='my-2 w-100'>
										<Input
											type='email'
											name='email'
											id='email'
											placeholder='Email'
											onChange={(e) => {
												setInterestedUser({
													...interestedUser,
													email: e.target.value,
												});
											}}
											className='common-field-box'
											value={interestedUser.email}
										/>
									</FormGroup>
								</React.Fragment>
								<span
									style={{
										fontSize: 16,
										fontWeight: 400,
										color: 'red',
									}}
								>
									{_error}
								</span>
								{loading ? (
									<div className='w-100 py-2 my-2 d-flex justify-content-center align-items-center continue-btn-field-box'>
										<Loader size={25} thickness={0.1} />
									</div>
								) : (
									<Button
										className='w-100 my-2 continue-btn-field-box'
										type='submit'
										onSubmit={addInterestedUser}
									>
										Subscribe Now
									</Button>
								)}
							</Form>
						</Col>
						<Col
							xs='0'
							md='6'
							className='img-container overflow-hidden d-none d-md-block'
						>
							<div className='w-100 h-100 position-relative'>
								<div
									style={{
										width: '90%',
										height: '90%',
										position: 'absolute',
										top: '0',
										left: '0',
										backgroundColor: '#E4D0F4',
										borderRadius: '80px 0px 80px 0px',
										zIndex: '1',
									}}
									className='underlay'
								/>
								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
									alt=''
									style={{
										height: '90%',
										width: '90%',
										objectFit: 'cover',
										position: 'absolute',
										bottom: '0',
										right: '0',
										borderRadius: '80px 0px 80px 0px',
										zIndex: '2',
									}}
									className='overlay-img'
								/>
							</div>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	);
}
